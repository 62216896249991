<template>
  <div>
    <div v-if="!showWxChat && dialogVisible">
      <el-dialog title="订单确认" :visible.sync="dialogVisible" width="50%">
        <el-descriptions :column="1" border>
          <el-descriptions-item label="充值类型">
            <span v-if="payInfo.orderType == 1">会员充值</span>
            <span v-else-if="payInfo.orderType == 2">权益充值</span>
            <span v-else-if="payInfo.orderType == 3 || payInfo.orderType == 4">课件购买</span>
          </el-descriptions-item>
          <el-descriptions-item label="商品名称">{{
            payInfo.productName
          }}</el-descriptions-item>
          <el-descriptions-item label="商品数量">{{
            payInfo.num
          }}</el-descriptions-item>
          <el-descriptions-item label="商品价格">{{
            payInfo.amount
          }}</el-descriptions-item>
        </el-descriptions>
        <div class="pay-mode">
          <div
            :class="tabIndex == 2 ? 'active' : ''"
            class="pay-cotent1"
            @click="toPay(2)"
          >
            <img
              class="pay-img"
              src="@/assets/personCenter/memberCenter/aliPay-pay.png"
              alt=""
            />
            <div v-if="tabIndex == 2" class="active-icon"><span>√</span></div>
          </div>
          <div
            :class="tabIndex == 1 ? 'active' : ''"
            class="pay-cotent2"
            @click="toPay(1)"
          >
            <img
              class="pay-img"
              src="@/assets/personCenter/memberCenter/weChat-pay.png"
              alt=""
            />
            <div v-if="tabIndex == 1" class="active-icon"><span>√</span></div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="toPurchase">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div v-if="showWxChat">
      <el-dialog
        title="微信扫码支付"
        :close-on-click-modal="false"
        :show-close="false"
        :visible.sync="showWxChat"
        width="30%"
      >
        <div class="qrcode-box title">
          <div class="code-title">支付成功/失败后 请点击确定后再刷新页面</div>
        </div>
        <div class="qrcode-container">
          <div class="qrcode-box" id="qrcode"></div>
        </div>
        <div class="qrcode-box"><span class="left-title">支付金额:</span> <span class="right-pay">{{ text }} 元</span></div>
        <!--
        <div class="footer-text">
          <img
            class="pay-img"
            src="@/assets/personCenter/memberCenter/wxChat-s.png"
            alt=""
          />
          <div class="right">
            请使用微信扫一扫<br />
            扫描二维码支付
          </div>
        </div>
         -->
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="toRefresh">取消</el-button> -->
          <el-button type="primary" @click="toRefresh">完成</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { jcCourseApplyAdd, jcCourseApplyPage } from "@/api/course/course";
import { jcTrainingApplyAdd } from "@/api/training/training";
import { jobOrderItem } from "@/api/personalCenter/personalCenter";
import { createOrder, aliPcPay, wxNativePay } from "@/api/pay/pay";
import QRCode from "qrcodejs2";
import moment from "moment";
export default {
  components: { QRCode },
  props: {
    payInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    payStatus: String,
  },
  watch: {
    payStatus: function (newValue) {
      if (newValue) {
        this.getFileList();
      }
      deep: true;
    },
  },
  data() {
    return {
      tabIndex: "",
      link: "weixin://wxpay/bizpayurl?pr=ci9RChnzz",
      showWxChat: false,
      text: "",
    };
  },
  methods: {
    toPurchase () {
      // console.log(this.payInfo);
      if (this.tabIndex != "") {
        this.payInfo.payMethod = this.tabIndex;
        if (this.payInfo.orderType <= 2) {
          jobOrderItem(this.payInfo)
            .then((res) => {
              this.dialogVisible = false;
              var order = res.data;
              order.outTradeNo = order.orderNo;
              this.payOrderMember(order)
              // if (this.payInfo.payMethod == 2) {
              //   // 支付
              //   aliPcPay(order).then((res) => {
              //     // console.log(res);
              //     const newWindow = window.open("", "_blank");
              //     newWindow.document.write(res);
              //     newWindow.focus();
              //   });
              // } else {
              //   this.text = Number(this.payInfo.amount).toFixed(2);
              //   wxNativePay(order).then((res) => {
              //     if (res.success) {
              //       // console.log(JSON.parse(res.data).code_url);
              //       this.link = JSON.parse(res.data).code_url;
              //       this.showWxChat = true;
              //       this.$nextTick(function () {
              //         this.qrcode();
              //       });
              //     }
              //   });
              // }
            })
            .catch((error) => {
              this.$message({
                type: "error",
                message: "购买失败",
              });
            });
        } else {
          // console.log(this.payInfo.orderType);
          let info = this.payInfo;
          this.text = Number(info.amount).toFixed(2);
          createOrder({
            amount: info.amount,
            jobUserId: info.jobUserId,
            payMethod: info.payMethod, // 1 ali , 2 wx
            orderType: info.orderType == "4" ? "3" : info.orderType, // 1 会员 ,2 权益 3 课程
            userType: info.userType, // 1 个人 ,2 企业
            productName: info.productName,
          }).then((res) => {
            var order = res.data;
            order.outTradeNo = order.orderNo;
            if (info.orderType == "4") {

              jcTrainingApplyAdd({
                jobUserId: info.jobUserId,
                jobTrainingId: info.jobTrainingId,
                outTradeNo: order.orderNo,
              }).then((res) => {
                if (res.success) {
                  this.dialogVisible = false;
                  this.payOrderCourse(order)
                  // if (this.payInfo.payMethod == 2) {
                  //   // 支付
                  //   aliPcPay(order).then((res) => {
                  //     const newWindow = window.open("", "_blank");
                  //     newWindow.document.write(res);
                  //     newWindow.focus();
                  //   });
                  // } else {
                  //   wxNativePay(order).then((res) => {
                  //     console.log(res);
                  //     if (res.success) {
                  //       console.log(JSON.parse(res.data).code_url);
                  //       this.link = JSON.parse(res.data).code_url;
                  //       this.showWxChat = true;
                  //       this.$nextTick(function () {
                  //         this.qrcode();
                  //       });
                  //     }
                  //   });
                  // }
                }
              });
            } else {
              jcCourseApplyAdd({
                jobUserId: info.jobUserId,
                jobCourseId: info.jobCourseId,
                outTradeNo: order.orderNo,
              }).then((res) => {
                if (res.success) {
                  this.dialogVisible = false;
                  this.payOrderCourse(order)
                  // if (this.payInfo.payMethod == 2) {
                  //   // 支付
                  //   aliPcPay(order).then((res) => {
                  //     const newWindow = window.open("", "_blank");
                  //     newWindow.document.write(res);
                  //     newWindow.focus();
                  //   });
                  // } else {
                  //   wxNativePay(order).then((res) => {
                  //     console.log(res);
                  //     if (res.success) {
                  //       console.log(JSON.parse(res.data).code_url);
                  //       this.link = JSON.parse(res.data).code_url;
                  //       this.showWxChat = true;
                  //       this.$nextTick(function () {
                  //         this.qrcode();
                  //       });
                  //     }
                  //   });
                  // }
                }
              });
            }
          });
        }
      } else {
        this.$message({
          type: "none",
          message: "请选择支付方式",
        });
      }
    },
    toPay(val) {
      this.payInfo.payMethod = val;
      this.tabIndex = val;
    },
    cancel() {
      this.dialogVisible = false;
      this.tabIndex = "";
    },
    qrcode() {
      let that = this;
      let qrcode = new QRCode("qrcode", {
        width: 224,
        height: 224,
        text: this.link,
      });
    },
    toRefresh() {
      this.showWxChat = false;
      if (!this.payStatus && this.payInfo.orderType == 3) {
        this.$parent.showApply(this.payInfo.jobUserId);
      }
      if (this.payInfo.orderType != 3) {
        this.$router.push('/personalCenter/myOrder')
      }
    },
    getFileList() {
      let info = this.payInfo;
      let order = {
        createTime: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        createUser: info.jobUserId,
        id: info.id,
        orderNo: this.payStatus,
        orderType: info.orderType,
        userType: info.userType,
        payMethod: info.payMethod,
        productName: info.productName,
        amount: info.amount,
        jobUserId: info.jobUserId,
        outTradeNo: this.payStatus,
      };
      if (this.payInfo.orderType != 3) {
        order.num = info.num;
        order.productId = info.productId;
        this.payOrderMember(order);
      } else {
        this.payOrderCourse(order);
      }
    },
    payOrderMember(order) {
      if (this.payInfo.payMethod == 2) {
        // 支付
        aliPcPay(order).then((res) => {
          console.log(res);
          const newWindow = window.open("", "_blank");
          newWindow.document.write(res);
          newWindow.focus();
        });
      } else {
        this.text = Number(this.payInfo.amount).toFixed(2);
        wxNativePay(order).then((res) => {
          if (res.success) {
            console.log(JSON.parse(res.data).code_url);
            this.link = JSON.parse(res.data).code_url;
            this.showWxChat = true;
            this.$nextTick(function () {
              this.qrcode();
            });
          }
        });
      }
    },
    payOrderCourse(order) {
      console.log(order);
      if (this.payInfo.payMethod == 2) {
        // 支付
        aliPcPay(order).then((res) => {
          console.log(res);
          const newWindow = window.open("", "_blank");
          newWindow.document.write(res);
          newWindow.focus();
        });
      } else {
        this.text = Number(this.payInfo.amount).toFixed(2);
        wxNativePay(order).then((res) => {
          if (res.success) {
            console.log(JSON.parse(res.data).code_url);
            this.link = JSON.parse(res.data).code_url;
            this.showWxChat = true;
            this.$nextTick(function () {
              this.qrcode();
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}

.previewDialog {
  .create-info {
    display: flex;
    padding: 10px 20px;
  }

  .user-info {
    width: 28%;
    align-items: center;
    padding: 30px 30px;

    .logo-title {
      text-align: center;
      .logo-img {
        width: 47px;
        height: 53px;
      }
      .logo-text {
        color: #0d9ff9;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .title-name {
      padding: 10px 20px;
      margin-top: 20px;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
    }
    .user-name {
      font-size: 18px;
      font-weight: bold;
    }
    .left {
      .info-data {
        margin: 20px 0;
      }
    }
    .right {
      overflow: hidden;
      align-items: center;
      display: flex;
      .grade-title {
        font-size: 18px;
        margin-right: 20px;
      }
      .grade {
        color: #ff5a47;
        font-size: 28px;
        font-family: buer;
      }
    }
  }
  .detail-info {
    width: 55%;
    padding: 15px 0;
    margin-left: 40px;
    .title-name {
      padding: 10px 0;
      font-size: 18px;
      font-weight: bold;
    }
    .up-info {
      display: flex;
      justify-content: space-around;
      box-sizing: border-box;
      padding: 10px;
      margin: 7px 0;

      .left {
        width: 20%;
        font-size: 16px;
      }
      .right {
        flex: 1;
        width: 50%;
        font-size: 16px;
      }
    }
  }
}
.pay-mode {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  .pay-cotent1 {
    border: 1px solid #c0c0c0;
    width: 196px;
    height: 90px;
    border-radius: 5px;
    margin: 0 10px;
    text-align: center;
    position: relative;
    .pay-img {
      margin: 15px 0;
      width: 80%;
      height: 60%;
    }
  }
  .pay-cotent2 {
    border: 1px solid #c0c0c0;
    width: 196px;
    height: 90px;
    border-radius: 5px;
    text-align: center;
    position: relative;
    .pay-img {
      margin: 16px 0;
      width: 84%;
      height: 63%;
    }
  }
  .active {
    border: 1px solid #f01414;
  }
  .active-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-color: transparent red transparent transparent;
    border-style: solid;
    border-width: 24px 20px 0 0;
    color: #fff;
    span {
      position: absolute;
      top: -18px;
      right: -18px;
    }
  }
}
.qrcode-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 245px;
  height: 245px;
  border: 1px solid #cccccc;
  margin: 10px auto;
}
.qrcode-box {
  text-align: center;
  .code-title{
    color: #45B8FF;
    height: 29px;
    line-height: 29px;
    font-size: 19px;
    background-color: rgba($color: #F2F8FF, $alpha: 0.5);
  }
  .left-title{
    color: #666666;
    font-size: 20px;
  }
  .right-pay{
    margin-left: 30px;
    color: #FD7039;
    font-size: 20px;
  }
}
.footer-text {
  background-color: #0d9ff9;
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
  border-radius: 5px;

  .pay-img {
    width: 40px;
    height: 40px;
  }
  .right {
    margin-left: 30px;
    color: #fff;
    font-size: 18px;
  }
}
</style>
