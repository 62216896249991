import request from "@/api/request"



/**
 * 
 * pc支付宝 支付
 * @author vigour
 * @date 2022-10-17 17:09:36
 */
 export function aliPcPay (parameter) {
  return request({
    url: '/jobUser/aliPay/pcPay',
    method: 'get',
    params: parameter
  })
}

/**
 * 
 * 微信扫码 支付
 * @author vigour
 * @date 2022-10-27 17:09:36
 */
 export function wxNativePay (parameter) {
  return request({
    url: '/jobUser/wxPay/nativePay',
    method: 'post',
    data: parameter
  })
}



/**
 * 创建订单
 *
 * @author vigour
 * @date 2022-10-17 10:15:52
 */
 export function createOrder (parameter) {
  return request({
    url: '/jobUser/jobOrder/create',
    method: 'post',
    data: parameter
  })
}